<template>
  <div>
    <video ref="video" width="300" height="300" autoplay></video>
    <div>
      <button @click="takePhoto">Take Photo</button>
      <button @click="switchCamera">Switch Camera</button>
    </div>
    <canvas ref="canvas" width="300" height="300" style="display:none;"></canvas>
    <img v-if="photo" :src="photo" alt="Captured photo"/>
  </div>
</template>

<script>
export default {
  data() {
    return {
      stream: null,
      facingMode: 'user', // 'user' for front camera, 'environment' for back camera
      photo: null,
    };
  },
  mounted() {
    this.startCamera();
  },
  beforeUnmount() { // Updated this line
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
    }
  },
  methods: {
    async startCamera() {
      try {
        this.stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: this.facingMode }
        });
        this.$refs.video.srcObject = this.stream;
      } catch (err) {
        console.error("Error accessing camera: ", err);
      }
    },
    takePhoto() {
      const canvas = this.$refs.canvas;
      const context = canvas.getContext('2d');
      context.drawImage(this.$refs.video, 0, 0, canvas.width, canvas.height);
      this.photo = canvas.toDataURL('image/png');
    },
    switchCamera() {
      this.facingMode = this.facingMode === 'user' ? 'environment' : 'user';
      this.startCamera();

    
    }
  }
};
</script>

<style scoped>
/* Add your styles here */
</style>
