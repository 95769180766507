<template>
  <div id="app">
    <h1>Camera Capture PWA</h1>
    <CameraCapture />
  </div>
</template>

<script>
import CameraCapture from './components/CameraCapture.vue';

export default {
  components: {
    CameraCapture,
  },
};
</script>

<style>
/* Global styles */
</style>
